@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

$text-color: #f4f3ee;

:root {
  /* Color palette: https://coolors.co/1c4442-78f9a0-ff5e5b-cd4c4a-f4f3ee-1e91d6 */
  --dark-turquoise: #1c4442;
  --light-green: #78f9a0;
  --pastel-red: #ed5754;
  /* #dd6c5a */
  --wine-red: #cd4c4a;
  /* #684551 */
  --text-color: #f4f3ee;
  --text-color-hover: var(--wine-red); /* 4E2086 */
  /* #EFECCA #fff */
  --bike-post-color: #1e91d6;

  --max-width: 700px;
}

html {
  padding: 0;
}

body {
  background-color: var(--dark-turquoise);
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  margin: 0;
  font-size: 18px;
  line-height: 1.4211;
  font-weight: 400;
  letter-spacing: 0.012em;

  > main {
    max-width: var(--max-width);
    margin: 2em auto;
  }
}

h1 {
  color: var(--light-green);
  font-size: clamp(3rem, 8vw + 1rem, 6rem);
  text-align: center;
  margin: 0 1rem 0;
}

h1 a,
h1 a:hover {
  color: inherit;
  text-decoration: none;
}

h2 {
  color: var(--light-green);

  &.subtitle {
    color: var(--text-color);
    font-size: 1.17em;
    font-weight: 300;
    margin: 1em 1rem clamp(2em, 8vw + 1rem, 4em);
    text-align: center;
  }
}

h3 {
  color: var(--text-color);
}

a {
  color: var(--light-green);
  transition: color 0.2s;

  &:hover {
    color: #1c4442;
  }
}

@keyframes pulse {
  0% {
  }
  50% {
    filter: blur(0.2em);
    opacity: 0.8;
  }
  100% {
  }
}

footer {
  background-color: var(--light-green);
  color: var(--dark-turquoise);
  padding: 3em 1em;
  text-align: center;

  a {
    color: var(--dark-turquoise);
    font-weight: bold;
  }
}

// Import partials.
@import "trailstories/post", "trailstories/tag", "trailstories/header",
  "trailstories/footer";
