%tag {
  font-size: 0.6rem;
  background-color: var(--text-color);
  opacity: 0.4;
  padding: 0.1em 0.4em;
  color: var(--pastel-red);
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.2s;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.2em;
  letter-spacing: 0.05em;

  &:hover {
    opacity: 1;
  }
}

.tags {
  line-height: 0.8em;

  a {
    @extend %tag;
    display: inline-block;
    margin-bottom: 0;
  }
}

.tags-wrapper {
  h1 {
    margin-bottom: 1em;
  }

  h2.tag {
    @extend %tag;
    font-size: 2rem;
    opacity: 1;
    color: var(--dark-turquoise);
    display: inline-block;
    margin-bottom: 0;
    margin-top: 5em;
  }

  .tag-cloud.tags {
    a {
      color: var(--dark-turquoise);
      font-size: 1rem;
      line-height: 2.2em;
    }
  }
}

@media (max-width: 700px) {
  .tags-wrapper {
    h2.tag {
      margin-left: 1em;
    }

    .tag-cloud.tags {
      padding: 2em;
    }
  }
}
