$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$spacing-unit: 30px !default;
$base-font-size: 16px !default;
$on-palm: 600px !default;
$on-laptop: 800px !default;

%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc(#{$spacing-unit} / 2);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: calc(-#{$spacing-unit} / 2);
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: calc(#{$spacing-unit} / 2);
  padding-left: calc(#{$spacing-unit} / 2);
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #{$grey-color};
  padding-right: 5px;
  vertical-align: text-top;
}
